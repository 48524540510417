<!-- 数据汇总 -->
<template>
  <div class="wrap">
    <el-tabs v-model="active" @tab-click="tabClickHandler">
      <el-tab-pane label="线上/线下" name="a">
        <div v-loading="loading">
          <div class="smk-flex">
            <div v-for="(item,index) in generalA" :key="index" class="smk-wrap">
              <p class="smk-title">{{item.title}}</p>
              <p class="smk-value">{{item.value}}</p>
            </div>
          </div>
          <!-- <hr /> -->
          <div ref="line" style="width:100%;height:35vh;min-height:320px"></div>
          <div class="disFlex">
            <div ref="pieResume" style="width:330px;height:45vh"></div>
            <div ref="pieEdu" style="width:330px;height:45vh"></div>

            <!-- <div ref="pieSex" style="width:33%;height:45vh"></div> -->
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="猎场" name="b">
        <div v-loading="loadingB">
          <div class="smk-flex">
            <div v-for="(item,index) in generalB" :key="index" class="smk-wrap">
              <p class="smk-title">{{item.title}}</p>
              <p class="smk-value">{{item.value}}</p>
            </div>
          </div>
          <!-- <hr /> -->

          <div ref="lineB" style="width:1100px;height:35vh;min-height:320px"></div>
          <div class="disFlex">
            <div ref="pieResumeB" style="width:330px;height:45vh"></div>
            <div ref="pieEduB" style="width:330px;height:45vh"></div>

            <!-- <div ref="pieSex" style="width:33%;height:45vh"></div> -->
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

// 初始展示数据
import {
  optionLine,
  optionPie,
  optionPieLC,
  optionPieEdu,
  optionPieSex,
  resumeLine,
} from "@/utils/echarts";

// 接口展示数据
import { getEchartsA, getEchartsB } from "@/api/tool/index";

let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/pie");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      loadingB: true,
      // 指定图表的配置项和数据
      // 使用刚指定的配置项和数据显示图表。
      myChartlLine: null,
      myChartpieResume: null,
      myChartpieEdu: null,
      myChartpieSex: null,
      generalA: [
        { title: "简历总数", value: 0 },
        { title: "线上简历", value: 0 },
        { title: "线下简历", value: 0 },
        { title: "当日新增", value: 0 },
      ],
      generalB: [
        { title: "简历总数", value: 0 },
        { title: "当日新增", value: 0 },
      ],
      active: "a",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    tabClickHandler(tab) {
      console.log(tab);
      if (tab.name === "b") {
        this.apiB();
      } else if (tab.name === "a") {
        this.apiA();
      }
    },
    apiA() {
      getEchartsA().then((res) => {
        console.log(res);
        //线上线下
        optionLine.xAxis[0].data = res.data.time_list;
        optionLine.series.filter((item, index) => {
          if (index === 0) {
            item.data = res.data.line_chart_online;
          } else if (index === 1) {
            item.data = res.data.line_chart_not_online;
          }
          return item;
        });

        console.log(optionLine, "这是数据");
        this.myChartlLine.setOption(optionLine);
        //简历状态
        optionPie.series[0].data.filter((item, index) => {
          res.data.resume_status_list.filter((self, index1) => {
            if (index === index1) {
              item.value = self;
            }
          });
          return item;
        });
        this.myChartpieResume.setOption(optionPie);
        //学历
        optionPieEdu.series[0].data.filter((item, index) => {
          res.data.edu_lists.filter((self, index1) => {
            if (index === index1) {
              item.value = self;
            }
          });
          return item;
        });
        this.myChartpieEdu.setOption(optionPieEdu);
        // this.methodsAB(res)
        this.generalA.filter((item, index) => {
          res.data.resume_data_general.filter((self, index1) => {
            if (index === index1) {
              item.value = self;
            }
          });
          return item;
        });
        this.loading = false;
      });
    },
    apiB() {
      getEchartsB().then((res) => {
        this.methodsAB(res);
        this.generalB.filter((item, index) => {
          res.data.resume_data_general.filter((self, index1) => {
            if (index === index1) {
              item.value = self;
            }
          });
          return item;
        });
        this.loadingB = false;
      });
    },
    methodsAB(res) {
      resumeLine.xAxis[0].data = res.data.time_list;
      resumeLine.series.filter((item, index) => {
        if (index === 0) {
          item.data = res.data.line_chart_online;
        }
        return item;
      });

      this.myChartlLineB.setOption(resumeLine);

      //简历状态
      optionPieLC.series[0].data.filter((item, index) => {
        res.data.resume_status_list.filter((self, index1) => {
          if (index === index1) {
            item.value = self;
          }
        });
        return item;
      });
      this.myChartpieResumeB.setOption(optionPieLC);
      //学历
      optionPieEdu.series[0].data.filter((item, index) => {
        res.data.edu_lists.filter((self, index1) => {
          if (index === index1) {
            item.value = self;
          }
        });
        return item;
      });
      this.myChartpieEduB.setOption(optionPieEdu);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.apiA();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(optionPieSex, this.myChartlLine);
    this.myChartlLine = echarts.init(this.$refs.line);
    this.myChartpieResume = echarts.init(this.$refs.pieResume);
    this.myChartpieEdu = echarts.init(this.$refs.pieEdu);
    this.myChartlLineB = echarts.init(this.$refs.lineB);
    this.myChartpieResumeB = echarts.init(this.$refs.pieResumeB);
    this.myChartpieEduB = echarts.init(this.$refs.pieEduB);
    // this.myChartpieSex = echarts.init(this.$refs.pieSex)
    // this.myChartlLine.setOption(optionLine)
    // this.myChartpieResume.setOption(optionPie)
    // this.myChartpieEdu.setOption(optionPieEdu)
    // myChartpieSex.setOption(optionPieSex)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */
//我也不知道为啥这样写
@media screen and (min-width: 1000px) {
  .disFlex {
    display: flex;
    width: 1100px;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1100px) {
  .disFlex {
    width: 1000px;
    display: flex;
    justify-content: space-around;
  }
}
.wrap {
  @include padding;
  min-width: 1100px;
  //height: 100%;
  // padding-bottom: 0;
  margin-top: 80px;
  // background-color: red;
}
.smk-flex {
  display: flex;
  justify-content: space-around;
}
//el tab
div /deep/ .el-tabs__active-bar {
  background-color: $main_color;
}
div /deep/ .el-tabs__item:hover {
  color: $main_color;
}
div /deep/ .el-tabs__item.is-active {
  color: $main_color;
}
.smk-wrap {
  // box-shadow: $box-shadow;
  padding: 0 30px;
  margin-bottom: 40px;
}
.smk-title {
  color: #909399;
  font-size: 16px;
}
.smk-value {
  color: #ff9900;
  font-size: 16px;
  text-align: center;
}
</style>