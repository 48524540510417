var colors = ['#5793f3', '#d14a61', '#675bba'];
const resumeLine = {
  color: colors,
  tooltip: {
    trigger: 'item',
    formatter: function (params) {

      // console.log(params)
      return params.seriesName + '<br/>' + params.name + '有简历' + params.value + '份'
    }
  },
  legend: {
    data: ['简历总数']
  },
  grid: {
    top: 70,
    bottom: 50
  },
  xAxis: [
    {
      data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
    },
    {
      type: 'category',
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: '简历总数',
      type: 'line',
      // xAxisIndex: 1,
      smooth: true,
      data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],

    }

  ]
}

var colors = ['#5793f3', '#d14a61', '#675bba'];
const optionLine = {
  color: colors,
  tooltip: {
    trigger: 'item',
    formatter: function (params) {
      // console.log(params)
      return params.seriesName + '<br/>' + params.name + '有简历' + params.value + '份'
    }
  },
  legend: {
    data: ['线上简历', '线下简历']
  },
  grid: {
    top: 70,
    bottom: 50
  },
  xAxis: [
    {
      data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
    },
    {
      type: 'category',
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: '线上简历',
      type: 'line',
      // xAxisIndex: 1,
      smooth: true,
      data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
    },
    {
      name: '线下简历',
      type: 'line',
      // xAxisIndex: 1,

      smooth: true,
      data: [3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3, 0.7]
    }
  ]
}
//简历概况
const optionPie = {
  title: {
    text: '简历概况',

    left: 'center'
  },

  tooltip: {
    trigger: 'item',
    formatter: '{a}<br/>{b} : {c} ({d}%) '
    // formatter: function (data) {
    //   console.log(data)
    //   // return data.seriesName + "<br/>" + data.name + " : " + data.value + " (" + data.percent.toFixed(1) + "%)";
    // }
  },
  legend: {
    left: 'center',
    top: 'bottom',
    data: ['未处理', '不合格', '可复试', '发offer', '未入职']
  },
  // toolbox: {
  //   show: true,
  //   feature: {
  //     mark: { show: true },
  //     dataView: { show: true, readOnly: false },
  //     magicType: {
  //       show: true,
  //       type: ['pie', 'funnel']
  //     },
  //     restore: { show: true },
  //     saveAsImage: { show: true }
  //   }
  // },
  color: ['#00CCFF', '#F3552F', '#FFCC00', '#8CC118', '#FE2C8A'],
  series: [
    {
      name: '简历分布',
      type: 'pie',
      radius: ['50%', '70%'],

      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',

      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {

        length: 0.001
      },
      data: [
        { value: 10, name: '未处理' },
        { value: 10, name: '不合格' },
        { value: 10, name: '可复试' },
        { value: 10, name: '发offer' },
        { value: 10, name: '未入职' },

      ]
    },

  ]
}
//简历概况
const optionPieLC = {
  title: {
    text: '简历概况',

    left: 'center'
  },

  tooltip: {
    trigger: 'item',
    formatter: '{a}<br/>{b} : {c} ({d}%) '
    // formatter: function (data) {
    //   console.log(data)
    //   // return data.seriesName + "<br/>" + data.name + " : " + data.value + " (" + data.percent.toFixed(1) + "%)";
    // }
  },
  legend: {
    left: 'center',
    top: 'bottom',
    data: ['未标记', '不合格', '未接通', '可面试']
  },
  // toolbox: {
  //   show: true,
  //   feature: {
  //     mark: { show: true },
  //     dataView: { show: true, readOnly: false },
  //     magicType: {
  //       show: true,
  //       type: ['pie', 'funnel']
  //     },
  //     restore: { show: true },
  //     saveAsImage: { show: true }
  //   }
  // },
  color: ['#00CCFF', '#F3552F', '#FFCC00', '#8CC118'],
  series: [
    {
      name: '简历分布',
      type: 'pie',
      radius: ['50%', '70%'],

      avoidLabelOverlap: false,


      label: {
        show: false,
        position: 'center',

      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {

        length: 0.001
      },
      data: [
        { value: 10, name: '未标记' },
        { value: 10, name: '不合格' },
        { value: 10, name: '未接通' },
        { value: 10, name: '可面试' },


      ]
    },

  ]
}
//学历分布
const optionPieEdu = {
  title: {
    text: '学历分布',

    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'

  },
  color: [
    '#ccc',
    '#00FFFF',
    '#00FFA8',
    '#9F17FF',
    '#FFE400',
    '#F76F01',
    '#01A4F7',
    '#FE2C8A'
  ],

  legend: {
    left: 'center',
    top: 'bottom',
    data: ['初中', '中专/技校', '高中', '大专', '本科', '硕士', '博士', 'MBA']
  },
  // toolbox: {
  //   show: true,
  //   feature: {
  //     mark: { show: true },
  //     dataView: { show: true, readOnly: false },
  //     magicType: {
  //       show: true,
  //       type: ['pie', 'funnel']
  //     },
  //     restore: { show: true },
  //     saveAsImage: { show: true }
  //   }
  // },
  series: [
    {
      name: '学历分布',
      type: 'pie',
      // radius: [20, 110],
      // center: ['50%', '70%'],
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      // roseType: 'radius',
      label: {
        show: false,
        position: 'center',

      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {

        show: false
      },
      data: [
        { value: 0, name: '初中' },
        { value: 0, name: '中专/技校' },
        { value: 0, name: '高中' },
        { value: 0, name: '大专' },
        { value: 0, name: '本科' },
        { value: 0, name: '硕士' },
        { value: 0, name: '博士' },
        { value: 0, name: 'MBA' }

      ]
    },

  ]
}
//性别分布
const optionPieSex = {
  title: {
    text: '性别分布',

    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'

  },
  legend: {
    // orient: 'vertical',
    left: 'center',
    top: 'bottom',
    data: ['男', '女']
  },
  color: ['#329BC8', '#C96499'],
  series: [
    {
      name: '性别分布',
      type: 'pie',
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',

      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 30, name: '男' },
        { value: 135, name: '女' },

      ]
    }
  ]
};


export { optionLine, optionPie, optionPieEdu, optionPieSex, resumeLine, optionPieLC }